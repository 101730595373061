import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const imageList = [
  {
    label: 'Landscape',
    imgPath: 'https://images.unsplash.com/photo-1572244452840-208532d07f06?auto=format&fit=crop&w=1000&h=625&q=80',
  },
  {
    label: 'Mountains',
    imgPath: 'https://images.unsplash.com/photo-1572295727871-7638149ea3d7?auto=format&fit=crop&w=1000&h=625&q=60',
  },
  {
    label: 'Bali, Indonesia',
    imgPath: 'https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=1000&h=625&q=80',
  },
  {
    label: 'Hill',
    imgPath: 'https://images.unsplash.com/photo-1556515268-97d056bdb5a7?auto=format&fit=crop&w=1000&h=625&q=60',
  },
  {
    label: 'Rocks',
    imgPath: 'https://images.unsplash.com/photo-1579037568984-dd37ec6fb818?auto=format&fit=crop&w=1000&h=625&q=60',
  },
  {
    label: 'Lake 2',
    imgPath: 'https://images.unsplash.com/photo-1579037611768-298fcaad76e2?auto=format&fit=crop&w=1000&h=625&q=60',
  },
];

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 1000,
    flexGrow: 1,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  img: {
    height: 625,
    display: 'block',
    maxWidth: 1000,
    overflow: 'hidden',
    width: '100%',
  },
}));

function SwipeableTextMobileStepper () {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = imageList.length;

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleStepChange = step => {
    setActiveStep(step);
  };

  return (
    <div className={classes.root}>
      <Paper square elevation={0} className={classes.header}>
        <Typography>{imageList[activeStep].label}</Typography>
      </Paper>

      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {imageList.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 2 ? (
              <img className={classes.img} src={step.imgPath} alt={step.label}/>
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>

      <MobileStepper
        steps={maxSteps}
        position="static"
        variant="text"
        activeStep={activeStep}
        nextButton={
          <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
            Next
            {theme.direction === 'rtl' ? <KeyboardArrowLeft/> : <KeyboardArrowRight/>}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
            Back
          </Button>
        }
      />

    </div>
  );
}

export default SwipeableTextMobileStepper;
