import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        marginBottom: 10,
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        width: 151,
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    playIcon: {
        height: 38,
        width: 38,
    },
}));

export default function MediaControlCard(props) {
    const classes = useStyles();
    // const theme = useTheme();
    // const preventDefault = event => event.preventDefault();
    if (!props.data){
        return <div>Not found</div>
    }
    const {title, desc, url, date, author, image} = props.data

    return (
            <Card className={classes.root}>
                {image &&  <CardMedia
                    className={classes.cover}
                    image={image}
                    // image="/static/images/cards/live-from-space.jpg"
                    // image="https://images.unsplash.com/photo-1572244452840-208532d07f06?auto=format&fit=crop&w=1000&h=625&q=80"
                    title="Live from space album cover"
                />}

                <div className={classes.details}>
                    <CardContent className={classes.content}>
                        <Typography component="h5" variant="h5">

                            <a href={url}
                               target="_blank" rel="noopener noreferrer">
                                {title}
                            </a>

                        </Typography>

                        <Typography
                            component="span"
                            variant="body2"
                            className={classes.inline}
                            // color="textPrimary"
                        >
                            {desc}
                        </Typography>
                        <Typography variant="caption" color="textSecondary" display="block" gutterBottom>
                            {date}, {author}
                        </Typography>

                    </CardContent>
                    <div className={classes.controls}>
                        {/*<IconButton aria-label="previous">*/}
                        {/*    {theme.direction === 'rtl' ? <SkipNextIcon /> : <SkipPreviousIcon />}*/}
                        {/*</IconButton>*/}
                        {/*<IconButton aria-label="play/pause">*/}
                        {/*    <PlayArrowIcon className={classes.playIcon} />*/}
                        {/*</IconButton>*/}
                        {/*<IconButton aria-label="next">*/}
                        {/*    {theme.direction === 'rtl' ? <SkipPreviousIcon /> : <SkipNextIcon />}*/}
                        {/*</IconButton>*/}
                    </div>
                </div>

            </Card>
    );
}
