import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import ListSubheader from '@material-ui/core/ListSubheader';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
// import tileData from './tileData';

// DOCUMENTATION: https://material-ui.com/components/grid-list/

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: 500,
    height: 450,
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
}));

/**
 * The example data is structured as follows:
 *
 * import image from 'path/to/image.jpg';
 * [etc...]
 *
 * const tileData = [
 *   {
 *     img: image,
 *     title: 'Image',
 *     author: 'author',
 *   },
 *   {
 *     [etc...]
 *   },
 * ];
 */

const tileData = [
  {
    img: 'https://images.unsplash.com/photo-1579037568984-dd37ec6fb818?auto=format&fit=crop&w=1000&h=625&q=60',
    title: 'Image1',
    author: 'author1',
  },
  {
    img: 'https://images.unsplash.com/photo-1572244452840-208532d07f06?auto=format&fit=crop&w=1000&h=625&q=80',
    title: 'Image2',
    author: 'author2',
  },
  {
    img: 'https://images.unsplash.com/photo-1572295727871-7638149ea3d7?auto=format&fit=crop&w=1000&h=625&q=60',
    title: 'Image3',
    author: 'author3',
  },
  {
    img: 'https://images.unsplash.com/photo-1556515268-97d056bdb5a7?auto=format&fit=crop&w=1000&h=625&q=60',
    title: 'Image4',
    author: 'author4',
  }
];


export default function TitlebarGridList () {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <GridList cellHeight={180} className={classes.gridList}>

        <GridListTile key="Subheader" cols={2} style={{ height: 'auto' }}>
          <ListSubheader component="div">Products</ListSubheader>
        </GridListTile>

        {tileData.map(tile => (
          <GridListTile key={tile.img}>
            <img src={tile.img} alt={tile.title}/>
            <GridListTileBar
              title={tile.title}
              subtitle={<span>by: {tile.author}</span>}
              actionIcon={
                <IconButton aria-label={`info about ${tile.title}`} className={classes.icon}>
                  <InfoIcon/>
                </IconButton>
              }
            />
          </GridListTile>
        ))}
      </GridList>
    </div>
  );
}
