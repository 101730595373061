import React from 'react';
// @use "@material/image-list/mdc-image-list";
// import styles from './Catalog.module.css';
import './Catalog.module.scss';
// import "@material/image-list/mdc-image-list"
// import "@material/image-list/mdc-image-list.scss"
// @use "@material/image-list/mdc-image-list";
// @import "@material/image-list/mdc-image-list";

const Catalog = () => {
  return (
    <ul className="mdc-image-list my-image-list">
      <li className="mdc-image-list__item">
        <div className="mdc-image-list__image-aspect-container">
          <img className="mdc-image-list__image" src="https://images.unsplash.com/photo-1579037568984-dd37ec6fb818?auto=format&fit=crop&w=1000&h=625&q=60" alt=""/>
        </div>
        <div className="mdc-image-list__supporting">
          <span className="mdc-image-list__label">Text label</span>
        </div>
      </li>

      <li className="mdc-image-list__item">
        <div className="mdc-image-list__image-aspect-container">
          <img className="mdc-image-list__image" src="https://images.unsplash.com/photo-1579037568984-dd37ec6fb818?auto=format&fit=crop&w=1000&h=625&q=60" alt=""/>
        </div>
        <div className="mdc-image-list__supporting">
          <span className="mdc-image-list__label">Text label</span>
        </div>
      </li>

      <li className="mdc-image-list__item">
        <div className="mdc-image-list__image-aspect-container">
          <img className="mdc-image-list__image" src="https://images.unsplash.com/photo-1579037568984-dd37ec6fb818?auto=format&fit=crop&w=1000&h=625&q=60" alt=""/>
        </div>
        <div className="mdc-image-list__supporting">
          <span className="mdc-image-list__label">Text label</span>
        </div>
      </li>

      <li className="mdc-image-list__item">
        <div className="mdc-image-list__image-aspect-container">
          <img className="mdc-image-list__image" src="https://images.unsplash.com/photo-1579037568984-dd37ec6fb818?auto=format&fit=crop&w=1000&h=625&q=60" alt=""/>
        </div>
        <div className="mdc-image-list__supporting">
          <span className="mdc-image-list__label">Text label</span>
        </div>
      </li>

      <li className="mdc-image-list__item">
        <div className="mdc-image-list__image-aspect-container">
          <img className="mdc-image-list__image" src="https://images.unsplash.com/photo-1579037568984-dd37ec6fb818?auto=format&fit=crop&w=1000&h=625&q=60" alt=""/>
        </div>
        <div className="mdc-image-list__supporting">
          <span className="mdc-image-list__label">Text label</span>
        </div>
      </li>
    </ul>
  )
}

export default Catalog
