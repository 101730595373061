import React from 'react';
import Image from 'material-ui-image';

const Blog = () => {

  return (
    <>
    <Image style={{marginLeft: '-24px', marginRight: '-24px', marginBottom: '24px', marginTop: '-24px'}} // mx={0} , 'paddingBottom': '600px' 'padding': '0px' margin: '-25px'
      // src={state.show ? `https://i.ytimg.com/vi/yaqe1qesQ8c/maxresdefault.jpg?_=${state.count}` : ''}
      src={'https://images.unsplash.com/photo-1579037611768-298fcaad76e2?auto=format&fit=crop&w=1000&h=625&q=60'}
      onClick={() => console.log('onClick')}
      aspectRatio={(16 / 9)}
      disableSpinner
    />

    <Image
      // src={state.show ? `https://i.ytimg.com/vi/yaqe1qesQ8c/maxresdefault.jpg?_=${state.count}` : ''}
      src={'https://images.unsplash.com/photo-1572295727871-7638149ea3d7?auto=format&fit=crop&w=1000&h=625&q=60'}
      onClick={() => console.log('onClick')}
      aspectRatio={(16 / 9)}
      disableSpinner
    />

    <Image
      // src={state.show ? `https://i.ytimg.com/vi/yaqe1qesQ8c/maxresdefault.jpg?_=${state.count}` : ''}
      src={'https://images.unsplash.com/photo-1579037568984-dd37ec6fb818?auto=format&fit=crop&w=1000&h=625&q=60s'}
      onClick={() => console.log('onClick')}
      aspectRatio={(16 / 9)}
      disableSpinner
    />

    <Image
      // src={state.show ? `https://i.ytimg.com/vi/yaqe1qesQ8c/maxresdefault.jpg?_=${state.count}` : ''}
      src={'https://images.unsplash.com/photo-1579037611768-298fcaad76e2?auto=format&fit=crop&w=1000&h=625&q=60'}
      onClick={() => console.log('onClick')}
      aspectRatio={(16 / 9)}
      disableSpinner
    />

    <Image
      // src={state.show ? `https://i.ytimg.com/vi/yaqe1qesQ8c/maxresdefault.jpg?_=${state.count}` : ''}
      src={'https://images.unsplash.com/photo-1579037611768-298fcaad76e2?auto=format&fit=crop&w=1000&h=625&q=60'}
      onClick={() => console.log('onClick')}
      aspectRatio={(16 / 9)}
      disableSpinner
    />

    <Image
      // src={state.show ? `https://i.ytimg.com/vi/yaqe1qesQ8c/maxresdefault.jpg?_=${state.count}` : ''}
      src={'https://images.unsplash.com/photo-1579037611768-298fcaad76e2?auto=format&fit=crop&w=1000&h=625&q=60'}
      onClick={() => console.log('onClick')}
      aspectRatio={(16 / 9)}
      disableSpinner
    />


    </>
  )
}

export default Blog
