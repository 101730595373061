import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {HashRouter as Router, Link, Route} from "react-router-dom";
import Photos from './Photos'
import Blog from './Blog'
import Catalog from "./Catalog";
import Products from "./Products";
import SignIn from "./SignIn";
import Timeclock from "./Timeclock";
import News from "./News";
import Card from "./Card";
import Code from "./Code";
// import {Close as CloseIcon, Menu as MenuIcon} from '@material-ui/icons';
import {Close as CloseIcon} from '@material-ui/icons';
// import Badge from '@material-ui/core/Badge';
import MenuIcon from '@material-ui/icons/Menu';
// import AccountCircle from '@material-ui/icons/AccountCircle';
// import MailIcon from '@material-ui/icons/Mail';
// import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import ExitToApp from '@material-ui/icons/ExitToApp';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import getData from "../api/data";

const Home = SignIn //() => <h1>Home</h1>;
const Customers = SignIn
// const Code = () => <h1>Code</h1>;
const Store = () => <h1>Store</h1>;

const drawerWidth = 240;


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    closeMenuButton: {
        marginRight: 'auto',
        marginLeft: 0,
    },
    grow: {
        flexGrow: 1,
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
}));

function ResponsiveDrawer() {
    const dummyCategories = ['Customers', 'News', 'Card', 'Products', 'Catalog', 'Timeclock', 'Blog', 'Code', 'Store', 'Photos']
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = React.useState(false);

    function handleDrawerToggle() {
        setMobileOpen(!mobileOpen)
    }


    const drawer = (
        <div>
            <List>
                {dummyCategories.map((text, index) => (
                    <ListItem button key={text} component={Link} to={"/" + text} onClick={() => setMobileOpen(false)}>
                        {/*<ListItem button key={text}>*/}
                        {/*<ListItemIcon>{index % 2 === 0 ? <InboxIcon/> : <Mail/>}</ListItemIcon>*/}
                        <ListItemText primary={text}/>
                    </ListItem>
                ))}
            </List>
        </div>
    );

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    // const handleProfileMenuOpen = event => {
    //     setAnchorEl(event.currentTarget);
    // };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = event => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const handleProfile = () => {
        console.log("profile")
        handleMenuClose()
    }

    const handleLogout = () => {
        console.log("logout")

      getData('/logout')
          .then(data => {
            console.log(data.data)
          })
          .catch(error => {
            console.log(error)
          })
        handleMenuClose()
    }

    // const handleMobileLogout = () => {
    //     console.log("logout2")
    //     handleMenuClose()
    // }


    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            id={menuId}
            keepMounted
            transformOrigin={{vertical: 'top', horizontal: 'right'}}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            {/*<MenuItem onClick={handleMenuClose}>Profile</MenuItem>*/}
            <MenuItem onClick={handleProfile}>Profile</MenuItem>
            <MenuItem onClick={handleMenuClose}>My account</MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{vertical: 'top', horizontal: 'right'}}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            {/*<MenuItem>*/}
            {/*    <IconButton aria-label="show 4 new mails" color="inherit">*/}
            {/*        <Badge badgeContent={4} color="secondary">*/}
            {/*            <MailIcon/>*/}
            {/*        </Badge>*/}
            {/*    </IconButton>*/}
            {/*    <p>Messages</p>*/}
            {/*</MenuItem>*/}
            {/*<MenuItem>*/}
            {/*    <IconButton aria-label="show 11 new notifications" color="inherit">*/}
            {/*        <Badge badgeContent={11} color="secondary">*/}
            {/*            <NotificationsIcon/>*/}
            {/*        </Badge>*/}
            {/*    </IconButton>*/}
            {/*    <p>Notifications</p>*/}
            {/*</MenuItem>*/}
            {/*<MenuItem onClick={handleProfileMenuOpen}>*/}
            {/*    <IconButton*/}
            {/*        aria-label="account of current user"*/}
            {/*        aria-controls="primary-search-account-menu"*/}
            {/*        aria-haspopup="true"*/}
            {/*        color="inherit"*/}
            {/*    >*/}
            {/*        <AccountCircle/>*/}
            {/*    </IconButton>*/}
            {/*    <p>Profile</p>*/}
            {/*</MenuItem>*/}
            <MenuItem onClick={handleLogout}>
                <IconButton aria-label="Logout" color="inherit">
                    {/*<Badge badgeContent={11} color="secondary">*/}
                    {/*<NotificationsIcon/>*/}
                    <ExitToApp/>
                    {/*</Badge>*/}
                </IconButton>
                <p>Logout</p>
            </MenuItem>
        </Menu>
    );


    return (
        <div className={classes.root}>
            <Router>
                <CssBaseline/>

                <AppBar position="fixed" className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="Open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            className={classes.menuButton}
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Typography variant="h6">
                            zelenko.org
                        </Typography>

                        {/*<Typography variant="h6" noWrap component={Link} to="/">*/}
                        {/*  []*/}
                        {/*</Typography>*/}

                        <div className={classes.grow}/>
                        <div className={classes.sectionDesktop}>
                            {/*<IconButton aria-label="show 4 new mails" color="inherit">*/}
                            {/*    <Badge badgeContent={4} color="secondary">*/}
                            {/*        <MailIcon/>*/}
                            {/*    </Badge>*/}
                            {/*</IconButton>*/}
                            {/*<IconButton aria-label="show 17 new notifications" color="inherit">*/}
                            {/*    <Badge badgeContent={17} color="secondary">*/}
                            {/*        <NotificationsIcon/>*/}
                            {/*    </Badge>*/}
                            {/*</IconButton>*/}
                            <IconButton
                                edge="end"
                                aria-label="account of current user"
                                aria-controls={menuId}
                                aria-haspopup="true"
                                // onClick={handleProfileMenuOpen}
                                onClick={handleMobileMenuOpen}
                                color="inherit"
                            >
                                {/*<AccountCircle/>*/}
                                <MoreIcon/>
                            </IconButton>
                        </div>
                        <div className={classes.sectionMobile}>
                            <IconButton
                                aria-label="show more"
                                aria-controls={mobileMenuId}
                                aria-haspopup="true"
                                onClick={handleMobileMenuOpen}
                                color="inherit"
                            >
                                <MoreIcon/>
                                {/*<AccountCircle/>*/}
                            </IconButton>
                        </div>
                    </Toolbar>
                </AppBar>
                {renderMobileMenu}
                {renderMenu}


                <nav className={classes.drawer}>
                    {/* The implementation can be swapped with js to avoid SEO duplication of links. */}

                    {/* Mobil */}
                    <Hidden smUp implementation="css">
                        <Drawer
                            variant="temporary"
                            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                        >
                            <IconButton onClick={handleDrawerToggle} className={classes.closeMenuButton}>
                                <CloseIcon/>
                            </IconButton>
                            {drawer}
                        </Drawer>
                    </Hidden>

                    {/* Desktop */}
                    <Hidden xsDown implementation="css">
                        <Drawer
                            className={classes.drawer}
                            variant="permanent"
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                        >
                            <div className={classes.toolbar}/>
                            {drawer}
                        </Drawer>
                    </Hidden>
                </nav>


                <div className={classes.content}>
                    <div className={classes.toolbar}/>
                    {/*<VisibleItemList />*/}
                    <Route path="/" exact component={Home}/>
                    <Route path="/Customers" exact component={Customers}/>
                    <Route path="/Products" exact component={Products}/>
                    <Route path="/Catalog" exact component={Catalog}/>
                    <Route path="/Timeclock" exact component={Timeclock}/>
                    <Route path="/Blog" exact component={Blog}/>
                    <Route path="/Code" exact component={Code}/>
                    <Route path="/Store" exact component={Store}/>
                    <Route path="/Photos" exact component={Photos}/>
                    <Route path="/News" exact component={News}/>
                    <Route path="/Card" exact component={Card}/>

                </div>

            </Router>
        </div>

    );
}

export default ResponsiveDrawer;
