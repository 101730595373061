import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import App from './App';
// import Album from "./components/Album";
// import Dashboard from "./components/Dashboard/Dashboard";
import ResponsiveDrawer from "./components/ResponsiveDrawer";
// import ResponsiveIcons from './components/ResponsiveIcons'
// import * as serviceWorker from './serviceWorker';  <== will this cause error

// ReactDOM.render(<App />, document.getElementById('root'));
// ReactDOM.render(<Album />, document.getElementById('root'));
// ReactDOM.render(<Dashboard />, document.getElementById('root'));
ReactDOM.render(<ResponsiveDrawer />, document.getElementById('root'));
// ReactDOM.render(<ResponsiveIcons />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
