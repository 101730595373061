export function postData (endpoint, data) {

  if (!data || data === "") return Promise.reject('data is empty');
  if (!endpoint || endpoint === "") return Promise.reject('endpoint is empty');
  return fetch(endpoint, {
    body: JSON.stringify(data),
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
      Cache: 'no-cache',
    },
  })
  .then(async function (response) {
    if (!response.ok) {
      const r = await response.text();
      if (r && r !== "") return Promise.reject(r)
      else return Promise.reject('An error has occurred setting your LDAP settings')
    }
    return response.json();
  })
}


export default function getData (endpoint) {
  if (!endpoint || endpoint === "") return Promise.reject('endpoint is empty');
  return fetch(endpoint)
  .then(async function (response) {
    if (!response.ok) {
      const r = await response.text();
      if (r && r !== "") return Promise.reject(r)
      else return Promise.reject('An error has occurred setting your LDAP settings')
    }
    return response.json();
  })
}

export function uploadFile (url, formData) {

  return fetch(url, {
    method: 'POST',
    body: formData,
  })
  .then(async function (response) {
    if (!response.ok) {
      const r = await response.text();
      if (r && r !== "") return Promise.reject(r)
      else return Promise.reject('An error has occurred while uploading file.')
    }
    return response.json();
  })
}
