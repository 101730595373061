import React, {useEffect, useState} from 'react';
import TextField from '@material-ui/core/TextField';
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import WorkIcon from '@material-ui/icons/Work';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import CommentIcon from '@material-ui/icons/Comment';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import getData from "../api/data";


const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  root: {
    width: '100%',
    maxWidth: "sm",
    backgroundColor: theme.palette.background.paper,
    paddingBottom: theme.spacing(1),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  spacing: 8,
}));

// const testList =  [
//   {
//     "id": 1,
//     "username": "username",
//     "job": "real date",
//     "clockin": "2020-02-19T19:38:34.751496-05:00",
//     "clockout": "2020-02-19T19:44:00.676009-05:00"
//   },
//   {
//     "id": 2,
//     "username": "username",
//     "job": "real date",
//     "clockin": "2020-02-19T19:44:14.266817-05:00",
//     "clockout": "2020-02-19T19:55:39.536733-05:00"
//   },
//   {
//     "id": 3,
//     "username": "username",
//     "job": "real date",
//     "clockin": "2020-02-19T20:01:19.290471-05:00",
//     "clockout": "2020-02-19T20:17:11.975977-05:00"
//   },
//   {
//     "id": 5,
//     "username": "username",
//     "job": "real date",
//     "clockin": "2020-02-19T20:35:49.413329999-05:00",
//     "clockout": "0001-01-01T00:00:00Z"
//   }
// ]

const Timeclock = () => {

  const [value, setValue] = useState('');
  const [list, setList] = useState([]);

  useEffect(()=> {
    getData('/timer/entries')
    .then(data => {
      console.log(data.data)
      setList(data.data)
    })
    .catch(error => {
      console.log(error)
    })
  }, [])

  const handleChange = event => {
    setValue(event.target.value);
  };

  const classes = useStyles();


  // ["xs","sm","md","lg","xl",false]
  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline/>
      <div className={classes.paper}>


        <TextField
          id="outlined-multiline-flexible"
          label="Activity"
          multiline
          rowsMax="4"
          value={value}
          onChange={handleChange}
          variant="outlined"
          className={classes.root}
        />

        <Box clone my={3}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.button}
            startIcon={<SaveIcon/>}
            fullWidth
          >
            Clockout
          </Button>
        </Box>


        <List className={classes.root}>


          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <WorkIcon/>
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Imagine, Create, Improve" secondary="Feb 19, 2020"/>
            {/*<ListItemText primary="Photos" secondary="Jan 9, 2014"/>*/}
            <ListItemSecondaryAction>
              <Chip color="primary" size="small" label="5:39PM - 6PM"/>

            </ListItemSecondaryAction>
          </ListItem>

          <Divider variant="inset" component="li"/>

          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <WorkIcon/>
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Work" secondary="Jan 7, 2014"/>
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="comments">
                <CommentIcon/>
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>


          <Divider variant="inset" component="li"/>


          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <BeachAccessIcon/>
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Vacation" secondary="July 20, 2014"/>
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="comments">
                <CommentIcon/>
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>

          {list.map(v => {

            return (<React.Fragment key={v.id}><ListItem>
              <ListItemAvatar>
                <Avatar>
                  <WorkIcon/>
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={v.job} secondary={v.clockin}/>
              <ListItemSecondaryAction>
                <Chip color="primary" size="small" label="5:39PM - 6PM"/>

              </ListItemSecondaryAction>
            </ListItem>

            <Divider variant="inset" component="li"/>
            </React.Fragment>
          )
          })}


        </List>


      </div>
    </Container>
  )
}

export default Timeclock


