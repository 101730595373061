import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from "./Card";
import getData from "../api/data";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import Box from "@material-ui/core/Box";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        maxWidth: 860,
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: 'inline',
    },
}));

export default function AlignItemsList() {
    const classes = useStyles();

    const [list, setList] = useState([]);

    useEffect(() => {
        getData('/news/feed')
            .then(data => {
                // console.log(data.data)
                setList(data.data)
            })
            .catch(error => {
                console.log(error)
            })
    }, [])

    const history = useHistory();

    const refresh = () => {
        getData('/news/newfeed')
            .then(data => {
                // console.log(data.data)
                setList(data.data)
            })
            .catch(error => {
                if (error === 'not authorized user') {
                    console.log("go to login page")
                    history.push("/");
                } else {
                    console.log(error)
                }
            })
    }

    return (
        <React.Fragment>
            <Box clone my={3}>
                <Button
                    onClick={refresh}
                    variant="contained"
                    color="primary"
                    size="large"
                    className={classes.button}
                    startIcon={<SaveIcon/>}
                    fullWidth
                >
                    Refresh
                </Button>
            </Box>
            {list.map(v => <Card key={v.title} data={v}/>)}

        </React.Fragment>
    );
}
