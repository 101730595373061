import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { postData } from "../api/data";
import {useHistory} from "react-router-dom";

function Copyright () {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://zelenko.org/">
        zelenko.org
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn () {
  const classes = useStyles();
  const history = useHistory();

  const [value, setValue] = useState('');
  const [visible, setVisible] = useState(false);
  const [errorType, setErrorType] = useState(true);
  const [errorMsg, setErrorMsg] = useState("Error: ");

  const handleChange = event => {
    setValue(event.target.value);
  };

  const keyPress = event => {
    if (event.key === 'Enter') {
      signInButtonClick();
      event.preventDefault();
    }
  }

  const signInButtonClick = () => {
    postData('/login', { password: value })
    .then(data => {
      // setErrorMsg(data.data)
      // console.log(data)
      // setVisible(true)
      // setErrorType(false)
      history.push("/news");
    })
    .catch(error => {
      console.log(error)
      setErrorMsg(error)
      setVisible(true)
      setErrorType(true)
      // if (error === 'not authorized') {
      //   setLoggedIn(false)
      //   return
      // } else  if (error === 'decryption error') {
      //   // return
      // }
    })


  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline/>
      <div className={classes.paper}>

        <Avatar className={classes.avatar}><LockOutlinedIcon/></Avatar>

        <Typography component="h1" variant="h5">Sign in</Typography>

        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
          />

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            onSubmit={signInButtonClick}
            onKeyPress={keyPress}
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={value}
            autoFocus
            onChange={handleChange}
            {...(visible && { helperText: errorMsg, error: errorType })}
          />

          <FormControlLabel control={<Checkbox value="remember" color="primary"/>} label="Remember me"/>

          <Button type="button" fullWidth variant="contained" color="primary" className={classes.submit}
                  onClick={signInButtonClick}>
            Sign In
          </Button>

          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">Forgot password?</Link>
            </Grid>

            <Grid item>
              <Link href="#" variant="body2">{"Don't have an account? Sign Up"}</Link>
            </Grid>
          </Grid>

        </form>
      </div>

      <Box mt={8}>
        <Copyright/>
      </Box>

    </Container>
  );
}
